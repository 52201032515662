import React, { useMemo, useState, useEffect } from 'react';
import NavigationMenu from './NavigationMenu';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../styling/App.css';
import '../styling/HalfIronMan.css';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const HalfIronMan = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const allActivities = JSON.parse(localStorage.getItem('allActivities')) || [];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const stats = useMemo(() => {
    const triActivities = allActivities.filter(activity => 
      ['swim', 'cycle', 'run'].includes(activity.activity_type)
    );

    // Calculate totals
    const totals = {
      swim: { distance: 0, duration: 0, count: 0 },
      cycle: { distance: 0, duration: 0, count: 0 },
      run: { distance: 0, duration: 0, count: 0 }
    };

    triActivities.forEach(activity => {
      const type = activity.activity_type;
      totals[type].distance += activity.distance || 0;
      totals[type].duration += activity.duration || 0;
      totals[type].count += 1;
    });

    // Weekly summary for trend analysis
    const weeklyData = triActivities.reduce((acc, activity) => {
      const week = activity.start_time.substring(0, 10);
      if (!acc[week]) {
        acc[week] = { swim: 0, cycle: 0, run: 0, date: week };
      }
      acc[week][activity.activity_type] += activity.distance || 0;
      return acc;
    }, {});

    return {
      totals,
      weeklyData: Object.values(weeklyData).sort((a, b) => a.date.localeCompare(b.date))
    };
  }, [allActivities]);

  const chartData = {
    labels: stats.weeklyData.map(data => data.date),
    datasets: [
      {
        label: 'Swimming (km)',
        data: stats.weeklyData.map(data => (data.swim / 1000).toFixed(2)),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Cycling (km)',
        data: stats.weeklyData.map(data => (data.cycle / 1000).toFixed(2)),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
      {
        label: 'Running (km)',
        data: stats.weeklyData.map(data => (data.run / 1000).toFixed(2)),
        borderColor: 'rgba(54,162,235,1)',
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Distance (km)'
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Weekly Training Distance'
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <NavigationMenu />
        <div className="title-container">
          <img src="/swimbikerun.gif" alt="Triathlon Animation" className="yoga-animation" />
          <h1 className="App-title">Half Iron Man Training</h1>
          <img src="/swimbikerun.gif" alt="Triathlon Animation" className="yoga-animation" />
        </div>
      </header>
      <div className="stats-container">
        <div className="summary-cards">
          <div className="stat-card">
            <h3>Swimming</h3>
            <p>Total Distance: {(stats.totals.swim.distance / 1000).toFixed(2)} km</p>
            <p>Sessions: {stats.totals.swim.count}</p>
            <p>Avg Distance: {((stats.totals.swim.distance / stats.totals.swim.count) / 1000).toFixed(2)} km</p>
          </div>
          <div className="stat-card">
            <h3>Cycling</h3>
            <p>Total Distance: {(stats.totals.cycle.distance / 1000).toFixed(2)} km</p>
            <p>Sessions: {stats.totals.cycle.count}</p>
            <p>Avg Distance: {((stats.totals.cycle.distance / stats.totals.cycle.count) / 1000).toFixed(2)} km</p>
          </div>
          <div className="stat-card">
            <h3>Running</h3>
            <p>Total Distance: {(stats.totals.run.distance / 1000).toFixed(2)} km</p>
            <p>Sessions: {stats.totals.run.count}</p>
            <p>Avg Distance: {((stats.totals.run.distance / stats.totals.run.count) / 1000).toFixed(2)} km</p>
          </div>
        </div>

        <div className="chart-container">
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default HalfIronMan;