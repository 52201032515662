import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styling/ActivityCalendar.css';

// Define order of activities
const ACTIVITY_ORDER = ['yoga', 'swim', 'cycle', 'run', 'strength'];

const ACTIVITY_TYPES = {
  yoga: { name: 'Yoga', color: '#FF6B6B' },      // Coral red
  swim: { name: 'Swim', color: '#45B7D1' },      // Light blue
  cycle: { name: 'Cycle', color: '#96CEB4' },    // Sage green
  run: { name: 'Run', color: '#FF9F45' },        // Orange
  strength: { name: 'Strength', color: '#4ECDC4' } // Turquoise
};

const formatDuration = (seconds) => {
  const totalSeconds = Math.round(seconds);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  
  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatPace = (seconds, distance, type) => {
  if (!distance) return '';
  
  switch(type) {
    case 'swim': {
      const paceSeconds = seconds / (distance / 100);
      const paceMinutes = Math.floor(paceSeconds / 60);
      const remainingSeconds = Math.round(paceSeconds % 60);
      return `${paceMinutes}:${remainingSeconds.toString().padStart(2, '0')}/100m`;
    }
    case 'run': {
      const paceSeconds = seconds / (distance / 1000);
      const paceMinutes = Math.floor(paceSeconds / 60);
      const remainingSeconds = Math.round(paceSeconds % 60);
      return `${paceMinutes}:${remainingSeconds.toString().padStart(2, '0')}/km`;
    }
    case 'cycle':
      return `${((distance / 1000) / (seconds / 3600)).toFixed(1)} km/h`;
    default:
      return '';
  }
};

const ActivityCalendar = ({ data }) => {
  const [date, setDate] = useState(new Date());
  const [selectedActivities, setSelectedActivities] = useState(Object.keys(ACTIVITY_TYPES));
  const [popup, setPopup] = useState({ visible: false, data: null, position: { x: 0, y: 0 } });

  const activityDates = data.reduce((acc, activity) => {
    const dateStr = new Date(activity.start_time).toISOString().split('T')[0];
    acc[dateStr] = acc[dateStr] || new Set();
    acc[dateStr].add(activity.activity_type.toLowerCase());
    return acc;
  }, {});

  const handleActivityClick = useCallback((e, activity) => {
    e.stopPropagation();
    const rect = e.target.getBoundingClientRect();
    setPopup({
      visible: true,
      data: activity,
      position: { x: rect.left, y: rect.bottom }
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = () => setPopup(prev => ({ ...prev, visible: false }));
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const tileContent = useCallback(({ date, view }) => {
    if (view !== 'month') return null;

    const dateStr = date.toISOString().split('T')[0];
    const activities = activityDates[dateStr];
    if (!activities) return null;

    const filteredActivities = Array.from(activities)
      .filter(activity => selectedActivities.includes(activity))
      .sort((a, b) => ACTIVITY_ORDER.indexOf(a) - ACTIVITY_ORDER.indexOf(b));

    if (!filteredActivities.length) return null;

    return (
      <div className="activities-container">
        {filteredActivities.map(activity => {
          const activityData = data.find(d => 
            d.activity_type.toLowerCase() === activity && 
            new Date(d.start_time).toISOString().split('T')[0] === dateStr
          );
          return (
            <div 
              key={activity}
              className="activity-label"
              style={{ backgroundColor: ACTIVITY_TYPES[activity].color }}
              onClick={(e) => handleActivityClick(e, activityData)}
            >
              {ACTIVITY_TYPES[activity].name}
            </div>
          );
        })}
      </div>
    );
  }, [data, selectedActivities, handleActivityClick, activityDates]);

  const toggleActivity = useCallback((type) => {
    setSelectedActivities(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  }, []);

  return (
    <div className="calendar-container">
      <div className="activity-filter">
        {ACTIVITY_ORDER.map(type => (
          <button
            key={type}
            className={`filter-button ${selectedActivities.includes(type) ? 'active' : ''}`}
            onClick={() => toggleActivity(type)}
            style={{ 
              borderColor: ACTIVITY_TYPES[type].color,
              backgroundColor: selectedActivities.includes(type) ? ACTIVITY_TYPES[type].color : 'white',
              color: selectedActivities.includes(type) ? 'white' : ACTIVITY_TYPES[type].color
            }}
          >
            {ACTIVITY_TYPES[type].name}
          </button>
        ))}
      </div>
      <Calendar
        onChange={setDate}
        value={date}
        tileContent={tileContent}
        className="activity-calendar"
      />
      {popup.visible && popup.data && (
        <div 
          className="activity-popup"
          style={{ left: `${popup.position.x}px`, top: `${popup.position.y}px` }}
        >
          <div className="popup-content">
            <h4>{ACTIVITY_TYPES[popup.data.activity_type.toLowerCase()].name}</h4>
            <p>Duration: {formatDuration(popup.data.duration)}</p>
            {popup.data.activity_type.toLowerCase() !== 'yoga' && popup.data.distance && (
              <p>Distance: {(popup.data.distance / 1000).toFixed(2)} km</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityCalendar; 