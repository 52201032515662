import React from 'react';
import NavigationMenu from './NavigationMenu';
import LoadingSpinner from './LoadingSpinner';
import useDataValidation from '../hooks/useDataValidation';
import SummaryTable from './SummaryTable';
import DetailGraph from './DetailGraph';
import YogaCount from './YogaCount';
import '../styling/App.css';

const Yoga = () => {
  const { loading } = useDataValidation();

  if (loading) {
    return <LoadingSpinner />;
  }

  const yogaCount = JSON.parse(localStorage.getItem('yogaStreak')) || 0;
  const allYogaStats = JSON.parse(localStorage.getItem('allYogaStats')) || 0;
  const yogaActivities = JSON.parse(localStorage.getItem('yogaActivities')) || [];

  return (
    <div className="App">
      <header className="App-header">
        <NavigationMenu />
        <div className="title-container">
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
          <h1 className="App-title">Yoga Habits</h1>
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
        </div>
      </header>
      <div className="stats-container">
        <YogaCount count={yogaCount} />
        <SummaryTable data={allYogaStats} />
        <DetailGraph data={yogaActivities}/>
      </div>
    </div>
  );
};

export default Yoga; 