import { useState, useEffect } from 'react';
import { fetchYogaStreak, fetchAllActivities, fetchYogaStats, fetchYogaActivities } from '../database/database';

const useDataValidation = () => {
  const [loading, setLoading] = useState(true);
  const [yogaCount, setYogaCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [streak, allActivities, stats, yogaActivities] = await Promise.all([
          fetchYogaStreak(),
          fetchAllActivities(),
          fetchYogaStats(),
          fetchYogaActivities()
        ]);
        
        setYogaCount(streak);

        // Store in localStorage with 3-hour expiration
        const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000;
        const storageData = {
          yogaStreak: streak,
          allActivities: allActivities,
          allYogaStats: stats,
          yogaActivities: yogaActivities,
          expirationTime
        };

        Object.entries(storageData).forEach(([key, value]) => {
          localStorage.setItem(key, JSON.stringify(value));
        });
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const checkExpiration = () => {
      const expirationTime = localStorage.getItem('expirationTime');
      if (!expirationTime || new Date().getTime() > JSON.parse(expirationTime)) {
        clearLocalStorage();
        return false;
      }
      return true;
    };

    const clearLocalStorage = () => {
      localStorage.removeItem('yogaStreak');
      localStorage.removeItem('allActivities');
      localStorage.removeItem('allYogaStats');
      localStorage.removeItem('yogaActivities');
      localStorage.removeItem('expirationTime');
    };

    const loadData = () => {
      const streak = JSON.parse(localStorage.getItem('yogaStreak'));
      const activities = JSON.parse(localStorage.getItem('allActivities'));
      const stats = JSON.parse(localStorage.getItem('allYogaStats'));
      const yogaActivities = JSON.parse(localStorage.getItem('yogaActivities'));

      if (streak && activities && stats) {
        setYogaCount(streak);
        setLoading(false);
      } else {
        fetchData();
      }
    };

    if (checkExpiration()) {
      loadData();
    } else {
      fetchData();
    }
  }, []);

  return { loading, yogaCount };
};

export default useDataValidation; 