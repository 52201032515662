import React from 'react';
import NavigationMenu from './NavigationMenu';
import LoadingSpinner from './LoadingSpinner';
import useDataValidation from '../hooks/useDataValidation';
import YogaCount from './YogaCount';
import ActivityCalendar from './ActivityCalendar';
import '../styling/App.css';
import '../styling/HomePage.css';

const HomePage = () => {
  const { loading } = useDataValidation();

  if (loading) {
    return <LoadingSpinner />;
  }

  const allActivities = JSON.parse(localStorage.getItem('allActivities')) || [];

  return (
    <div className="App">
      <header className="App-header">
        <NavigationMenu />
        <div className="title-container">
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
          <h1 className="App-title">HabitSync</h1>
          <img src="/swimbikerun.gif" alt="Swim Bike Run Animation" className="yoga-animation swimbikerun-animation" />
        </div>
      </header>
      <div className="stats-container">
        <YogaCount count={JSON.parse(localStorage.getItem('yogaStreak')) || 0} />
        <ActivityCalendar data={allActivities} />
      </div>
    </div>
  );
};

export default HomePage;