import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '../styling/App.css';
import HomePage from './HomePage';
import Yoga from './Yoga';
import HalfIronMan from './HalfIronMan';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/yoga" element={<Yoga />} />
          <Route path="/half-iron-man" element={<HalfIronMan />} />
        </Routes>
      </header>
    </div>
  );
};

export default App;