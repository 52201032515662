import React from 'react';

const LoadingSpinner = () => (
  <div className="App">
    <header className="App-header">
      <div className="loading-spinner">
        <img src="loading.gif" alt="Loading..." />
      </div>
    </header>
  </div>
);

export default LoadingSpinner; 