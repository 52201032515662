import React from 'react';
import '../styling/YogaCount.css';

const YogaCount = ({ count }) => {
  return (
    <div className="stats-row">
      <div className="stat-item">
        <h3>Yoga in a Row</h3>
        <p className="stat-value">{count}</p>
      </div>
  </div>
  );
};

export default YogaCount;