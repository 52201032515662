// API base URL
const BASE_URL = 'https://habitsync-backend.lemonbeach-3574a355.canadacentral.azurecontainerapps.io/api';

// Generic fetch function with error handling
async function fetchFromAPI(endpoint) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching from ${endpoint}:`, error);
    throw error;
  }
}

// Specific API endpoints
export async function fetchAllActivities() {
  return fetchFromAPI('/all-activities');
}

export async function fetchYogaStreak() {
  const data = await fetchFromAPI('/yoga-streak');
  return data.streak;
}

export async function fetchYogaStats() {
  return fetchFromAPI('/yoga-stats');
}

export async function fetchYogaActivities() {
  return fetchFromAPI('/yoga-activities');
}
